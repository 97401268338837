import React from 'react'

const ProjectAboutClient = ({ client, style }) => (
  <div className="container" style={style}>
    <section className="project-client">
      <div className="row">
        <div className="col-md-12">
          <h2 className="mb-4">
            <span className="text-lowercase">About the client</span>
          </h2>
          <h2>
            <span className="client-name">{client.client_name}</span>
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8 col-md-6 col-sm-12 col-xs-12">
          <p>{client.client_description}</p>
          <p>
            <a
              href={client.client_website.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {client.client_website.nice_name}
            </a>
          </p>
          <hr className="spacer d-md-none" />
        </div>
        {client.client_logo && (
          <div className="col-xl-2 offset-xl-2 col-lg-3 offset-lg-1 col-md-4 offset-md-2 col-sm-6 offset-sm-0 col-xs-8">
            <img
              src={client.client_logo.childImageSharp.fluid.src}
              alt={client.client_name}
              className="img-fluid"
            />
          </div>
        )}
      </div>
    </section>
  </div>
)

export default ProjectAboutClient
