import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleLeft } from '@fortawesome/pro-solid-svg-icons'
import Link from '../common/Link'

const ProjectNavBar = () => (
  <nav className="navbar fixed-top navbar-light bg-lightest">
    <div className="container">
      <ul className="nav navbar-nav">
        <li className="nav-item">
          <Link to="/projects/" className="nav-link">
            <FontAwesomeIcon icon={faArrowCircleLeft} /> back
          </Link>
        </li>
      </ul>
      <div className="float-right d-none d-md-block">
        <p className="text-right mb-0">
          <strong>MVP in 12 weeks and on budget?</strong> We've got you covered!
          <Link to="/estimate-app-cost/" className="btn btn-extra1 btn-sm ml-2">
            Get an estimate!
          </Link>
        </p>
      </div>
    </div>
  </nav>
)

export default ProjectNavBar
